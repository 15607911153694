// Include only variables and mixins to prevent unused CSS
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import 'styles/bootstrap-forms';

// Import rc-select styles (extends some Bootstrap form styles)
@import 'styles/rc-select';

// Import button styles
@import '~bootstrap/scss/mixins/buttons';
@import '~bootstrap/scss/buttons';

body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
